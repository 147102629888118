const Footer = () => {
    const socialData = [
        {
            imgUrl: "social-1",
            link: 'https://t.me/telegram'
        },
        {
            imgUrl: "social-4",
            link: 'https://uniswap.com'
        },
        {
            imgUrl: "social-5",
            link: 'https://coinmarketcap.com'
        },
        {
            imgUrl: "social-2",
            link: 'https://coingecko.com'
        },
        {
            imgUrl: "social-3",
            link: 'https://twitter.com'
        },

    ]
    return (<div className="w-full " >
        <footer className="bg-gray-800 text-white py-4">
        <div className=" mx-auto text-center">
          <p>&copy;2024 $Diamond</p>
        </div>
      </footer>
        
    </div>);
}

export default Footer;