import { useState, useRef } from "react";
import { motion } from 'framer-motion';
import { loaderVariants, slideIn } from "../utils/motion";
import { FaTelegram } from "react-icons/fa";

import { AiFillTwitterCircle, AiOutlineCopy, AiOutlineArrowRight, AiOutlineTwitter, AiOutlineRight, AiOutlineWallet } from "react-icons/ai"
import Button from "../utils/Button";
import AnimeLogo from "../utils/AnimeLogo";
import CA from "../utils/Ca";
import AOS from 'aos';
import 'aos/dist/aos.css'


const Hero = ({ pryColor
    , secColor }
) => {
    AOS.init({ once: true });

    const [isCopied, setIsCopied] = useState(false);
    const handleCopied = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 5000)
    }

    const contractRef = useRef();

    const copyContractText = () => {
        const text = contractRef.current.textContent;
        navigator.clipboard.writeText(text);
        console.log('Text copied: ' + text);
        handleCopied();
    };

    const buttonStyle = "bg-[#6495ED] text-[#fff] py-2 px-4 md:px-5 text-14px md:text-[18px] transform duration-300 hover:scale-110 rounded-md"

    return (<div className=" relative bg-[#80A9BE] font-spectral hero-section w-[100%] mx-auto  flex  flex-col px-4 p-20 md:px-4 mt-10 py-20 items-center justify-center pt-0 gap-2 md:gap-5">
        <div className="">



            {/* Main Content */}
            <main className="container mx-auto mt-8">
                {/* Featured Article */}
                <article className="mb-8">
                    <h2 className="text-3xl font-bold te mb-4">We all love Diamonds</h2>

                    <p className="text-lg leading-relaxed">

                        Diamonds, rare and precious, symbolize enduring love and timeless elegance, coveted for their unparalleled beauty and brilliance. Formed deep within the Earth's crust over millions of years, each diamond is a unique masterpiece, cherished for its rarity and significance. As a symbol of commitment and prestige, diamonds hold a profound importance in cultures worldwide, marking significant milestones and celebrating the enduring bonds of the heart.               </p>
                </article>
               
                <br />
                <div className="grid grid-cols-2 md:grid-cols-4 gap-5 bg-[#000] p-2 items-center">
                    <img src="../img/pic-0.jpg" alt="g1" />
                    <img src="../img/pic-2.gif" alt="g1" />
                    <img src="../img/pic-3.gif" alt="g1" />
                    <img src="../img/pic-4.gif" alt="g1" />
                </div>
                <br />
       
                <br />

                <div className="flex flex-col justify-center items-center w-full">
                    <h3 className="text-xl font-bold mb-2">Our Partners</h3>
                    {/* Other Articles */}
                    <div className="grid grid-cols-2 lg:grid-cols-3 w-full justify-center items-center gap-8">
                        {/* Article 1 */}
                        <article className="mb-4 flex flex-col items-center">
                            <a href="#"><img src="../img/dex.png" className="w-[250px]" alt="" /></a>
                        </article>
                        {/* Article 2 */}
                        <a href="#">
                            <article className="mb-4 flex flex-col items-center">
                                <img src="../img/cmc.png" className="w-[250px]" alt="" />
                            </article>
                        </a>
                        {/* Article 3 */}
                        <a href="#">
                            <article className="mb-4 flex flex-col items-center">
                                <img src="../img/ray.png" className="w-[250px]" alt="" />
                            </article>
                        </a>
                        {/* Article 4 */}
                    
                    </div>
                </div>
                <br /><br />
            </main>

            {/* Footer */}
            <footer className="bg-gray-800 md:hidden text-white py-4">
                <div className=" mx-auto text-center">
                    <p>&copy; 2024 $Diamond</p>
                </div>
            </footer>

        </div>
    </div>);
}

export default Hero;