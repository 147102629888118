import { motion } from 'framer-motion';
import { slideIn } from "../utils/motion";
import { AiFillInstagram, AiFillYoutube, AiOutlineArrowRight, AiOutlineTwitter } from 'react-icons/ai';
import Button from '../utils/Button';
import { SiTelegram } from 'react-icons/si';
import { FaTelegram } from 'react-icons/fa';

const Header = () => {

    return (<motion.div className="  text-[#000] bg-white border-b w-full border-gray-300 py-5 md:py-1  text-[16px] font-semibold flex  md:flex-row z-20 gap-5 items-center  justify-between md:justify-between px-10"
        variants={slideIn('up', 'spring', 0.1, 0.4)}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}

    >
         <header className=" md:w-[80%] mx-auto py-4">
        <div className="container mx-auto">
          <h1 className=" uppercase text-center text-4xl font-black text-gray-800">Diamond on Solana</h1>
        </div>
        <div className='flex flex-col items-center w-full justify-center gap-3'>
            <div className="flex  justify-center underline font-bold flex-row gap-5">
                <a href="https://x.com/thesoldiamond">Twitter</a>
                <a href="https://t.me/SOLDiamondPortal">Telegram</a>
                <a href="https://dexscreener.com/solana/B3Ce8Pkt7ynoARNzSK5uTCyGceMydiCGJ4w8Sm66Y4tD">Chart</a>
            </div>
            <div className='text-[12px] md:text-[20px]'>DA742YEG6T886whxJFFFSZ48iHj5JJiJyJoDokhBa49a</div>
        </div>
      </header>

    </motion.div>);
}

export default Header;